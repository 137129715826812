<nav class="bg-green-800">
  <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
    <div class="relative flex h-16 items-center justify-between">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <button
          type="button"
          aria-controls="mobile-menu"
          [attr.aria-expanded]="!hideMobileMenu"
          (click)="toggleMobileMenu()"
          (clickOutside)="hideMobileMenu = true"
          class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.
            Heroicon name: outline/bars-3
            Menu open: "hidden", Menu closed: "block"
          -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            [ngClass]="{ hidden: !hideMobileMenu }"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
          <!--
            Icon when menu is open.
            Heroicon name: outline/x-mark
            Menu open: "block", Menu closed: "hidden"
          -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
            [ngClass]="{ hidden: hideMobileMenu }"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div
        class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
      >
        <div class="flex flex-shrink-0 items-center">
          <!-- TODO: Tavis Logo?? -->
        </div>
        <div class="hidden sm:ml-6 sm:block">
          <div class="flex space-x-4">
            <a
              href="#"
              [routerLink]="['', 'home']"
              routerLinkActive="bg-green-900 text-white"
              class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
              aria-current="page"
            >
              Home
            </a>

            <a
              href="#"
              routerLink="leaderboard"
              routerLinkActive="bg-green-900 text-white"
              class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700"
            >
              Leaderboard
            </a>

            <a
              href="#"
              routerLink="oct-bonus"
              routerLinkActive="bg-green-900 text-white"
              class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700"
            >
              Oct Bonus
            </a>

            <ng-container *ngIf="this.userService?.xblUser$ | async as xblUser">
              <a
                href="#"
                [routerLink]="['/player/', xblUser.gamertag]"
                routerLinkActive="bg-green-900 text-white"
                class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700"
                *ngIf="isUserAuthenticated"
              >
                Profile
              </a>
            </ng-container>

            <a
              href="#"
              routerLink="admin"
              *ngIf="isUserAdmin"
              routerLinkActive="bg-green-900 text-white"
              class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
            >
              Admin
            </a>

            <a
              href="#"
              routerLink="sync"
              *ngIf="isUserAdmin"
              routerLinkActive="bg-green-900 text-white"
              class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
            >
              Sync Manager
            </a>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
      >
        <button
          *ngIf="isUserAuthenticated"
          type="button"
          class="rounded-full bg-green-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
        >
          <span class="sr-only">View notifications</span>
          <!-- Heroicon name: outline/bell -->
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
            />
          </svg>
        </button>

        <!-- Profile dropdown -->
        <div class="relative ml-3">
          <div (clickOutside)="hideProfileMenu = true">
            <button
              type="button"
              [attr.aria-expanded]="hideProfileMenu"
              aria-haspopup="true"
              (click)="toggleProfileMenu()"
              class="flex rounded-full bg-green-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              id="user-menu-button"
            >
              <span class="sr-only">Open user menu</span>
              <ng-container
                *ngIf="this.userService?.xblUser$ | async as xblUser"
              >
                <img
                  *ngIf="isUserAuthenticated"
                  class="h-8 w-8 rounded-full"
                  src="{{ xblUser.avatar }}"
                  alt=""
                />
              </ng-container>
              <div
                *ngIf="!isUserAuthenticated"
                class="relative h-8 w-8 overflow-hidden rounded-full bg-gray-600"
              >
                <svg
                  class="absolute -left-1 h-10 w-10 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
          </div>

          <div
            class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            [ngClass]="{ hidden: hideProfileMenu }"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <ng-container *ngIf="this.userService?.xblUser$ | async as xblUser">
              <a
                href="#"
                *ngIf="isUserAuthenticated"
                routerLink="profile"
                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                >Settings</a
              >
            </ng-container>
            <a
              href="#"
              *ngIf="isUserAuthenticated"
              (click)="logOut()"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              >Sign out</a
            >
            <!-- TODO: put a modal in front of this -->
            <a
              [href]="'https://xbl.io/app/auth/' + oxblAppKey"
              *ngIf="!isUserAuthenticated"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              tabindex="-1"
              id="user-menu-item-2"
              >Sign in</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div
    class="flex flex-col sm:hidden"
    id="mobile-menu"
    [ngClass]="{ hidden: hideMobileMenu }"
  >
    <div class="space-y-1 px-2 pb-3 pt-2">
      <a
        href="#"
        routerLink="home"
        routerLinkActive="bg-green-900 text-white"
        class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
        aria-current="page"
      >
        Home
      </a>

      <a
        href="#"
        routerLink="leaderboard"
        routerLinkActive="bg-green-900 text-white"
        class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
      >
        Leaderboard
      </a>

      <a
        href="#"
        routerLink="oct-bonus"
        routerLinkActive="bg-green-900 text-white"
        class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
      >
        Oct Bonus
      </a>

      <ng-container *ngIf="this.userService?.xblUser$ | async as xblUser">
        <a
          href="#"
          [routerLink]="['/player/', xblUser.gamertag]"
          routerLinkActive="bg-green-900 text-white"
          class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
          *ngIf="isUserAuthenticated"
        >
          Profile
        </a>
      </ng-container>

      <a
        href="#"
        routerLink="admin"
        *ngIf="isUserAdmin"
        routerLinkActive="bg-green-900 text-white"
        class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
      >
        Admin
      </a>

      <a
        href="#"
        routerLink="sync"
        *ngIf="isUserAdmin"
        routerLinkActive="bg-green-900 text-white"
        class="block rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-green-700 hover:text-white"
      >
        Sync Manager
      </a>
    </div>
  </div>
</nav>
<main style="margin: 20px">
  <router-outlet></router-outlet>
</main>
